import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import { Container } from '../components/Grid';
import Band from '../components/Band/Band';
import Typography from '../components/Typography';
import Contact from '../components/Contact/Contact';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO';
import Tiles from '../components/Tiles/Tiles';

export default ({ data }) => (
    <Layout>
        <SEO
            title="Expertise identifying recruitment opportunities"
            description="Our expertise and experience spans a diverse range of industries which allows us to identify recruitment opportunities that others may miss"
        />
        <Hero
            title="Black Dot expertise"
            mobileImage={data.mobileImage}
            desktopImage={data.desktopImage}
            logoPosition="top-right"
        />
        <Band size="md">
            <Container>
                <Typography color="turquoise">
                    <PageHeader>
                        Our expertise and experience spans a diverse range of industries which allows us to identify
                        opportunities that others may miss
                    </PageHeader>
                    <p>We place Juniors to SVP of Design and everything in between.</p>
                    <p>
                        Industrial Design | Design Engineering | Transportation Design | CMF | UI/UX &amp; Visual Design
                        | Digital Design | Design Research and Strategy | Business Design | Service Design &amp;
                        Storytelling | Project &amp; Product Management
                    </p>
                </Typography>
            </Container>
        </Band>
        <Band>
            <Tiles />
        </Band>
        <Band size="md">
            <Container>
                <Contact heading="Let's connect" />
            </Container>
        </Band>
    </Layout>
);

export const query = graphql`
    query ExpertisePageQuery {
        desktopImage: file(relativePath: { eq: "unsplash-4xXIX_qFy3c.jpg" }) {
            ...HeroBackgroundImage_Desktop
        }
        mobileImage: file(relativePath: { eq: "unsplash-sm-4xXIX_qFy3c.jpg" }) {
            ...HeroBackgroundImage_Mobile
        }
    }
`;
